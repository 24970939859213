/* You can add global styles to this file, and also import other style files */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  --mdc-shape-medium: theme(borderRadius.none);
  --mdc-typography-font-family: theme(fontFamily.body);
  --mdc-typography-body2-font-family: theme(fontFamily.sans);
  --mdc-typography-body2-font-weight: theme(fontWeight.semibold);
  --mdc-typography-body1-font-size: theme(fontSize.sm);
  --mdc-shape-small: theme(borderRadius.none);
  --mat-mdc-radio-checked-ripple-color: theme(colors.richBlack);
  --mdc-typography-button-font-family: theme(fontFamily.sans);
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  -webkit-clip-path: inset(-10px -6px 0px);
  clip-path: inset(-10px -6px 0px);
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-right-style: hidden;
}
.mat-mdc-input-element {
  box-shadow: none !important;
}

.ais-SearchBox-input {
  caret-color: rgba(243, 244, 246, 1) !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  color: #001427 !important;
}

.ais-SearchBox-input:focus {
  border-color: #475569 !important;
}

mux-player {
  object-fit: cover;
	--media-accent-color: #001427;
}

.mat-drawer-container {
  @apply bg-culturedWhite !important;
}

youtube-player > iframe {
  @apply w-full h-full;
}

.sami-snackbar {
  --mdc-snackbar-container-color: #ffffff !important;
}

.sami-dialog-no-padding mat-dialog-container {
  padding: 0 !important;
}

.sami-bottomsheet mat-bottom-sheet-container {
  @apply p-0 max-h-[90vh];
}

.ticket-type-dialog .mat-mdc-dialog-container {
  --mdc-dialog-container-color: theme(colors.culturedWhite);
}

app-center-area > .mat-drawer-container {
  z-index: 51;
}

.mat-tab-link {
  padding: 0 20px !important;
  min-width: 0 !important;
}

.home-nav .mat-tab-header-pagination-chevron {
  @apply border-culturedWhite;
}

.home-nav
  .mat-tab-header-pagination-disabled
  .mat-tab-header-pagination-chevron {
  @apply border-culturedWhite opacity-30;
}

.tox-tinymce {
  @apply border-cadetGrey-300 border rounded-none !important;
}

ngx-dropzone {
  @apply border-cadetGrey-300 border rounded-none border-dashed !important;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  overflow: hidden;
}

.offboarding_stepper .mat-horizontal-stepper-header-container {
  @apply hidden;
}

.offboarding_stepper .mat-horizontal-content-container {
  @apply p-0;
}

.sami-dialog mat-dialog-container {
  @apply p-0;
}

.sami-snackbar {
  --mdc-snackbar-container-color: theme(backgroundColor.white) !important;
}

.mat-pseudo-checkbox {
  border-radius: 0 !important;
}

.mat-drawer-backdrop {
  position: fixed !important;
}


lr-upload-list > .toolbar {
  @apply bg-richBlack !important;
}

.uc-settings {
  --cfg-pubkey: "be35bc43abef5402ecf4";
  --cfg-multiple: 0;
  --cfg-confirm-upload: 1;
  --cfg-img-only: 0;
  --cfg-accept: "video/*,application/octet-stream";
  --cfg-store: 1;
  --cfg-camera-mirror: 0;
  --cfg-source-list: "local, url, dropbox, gdrive, onedrive";
  --cfg-max-files: 1;
  --cfg-remove-copyright: 1;
  --border-radius-element: 0;
  --border-radius-frame: 0;
  --border-radius-thumb: 0;
  --shadows: 0;

  --h-background: 0;
  --s-background: 0%;
  --l-background: 100%;

  --h-error: 3;
  --s-error: 100%;
  --l-error: 35%;

  --h-accent: 209;
  --s-accent: 100%;
  --l-accent: 8%;

  --h-confirm: 144;
  --s-confirm: 95%;
  --l-confirm: 29%;
}